export const environment = {
  production: false,
  api: {
    url: 'https://vc-cpl-gateway-api.nt-development.dev/api',
  },
  website: 'https://www.google.com',
  featuresFlag: {
    user: {
      customer: true,
      expert: true,
    },
    exchange: true,
    voucher: true,
    bankAccounts: true,
    banks: true,
    kyc: true,
  },
};
