import { Routes } from '@angular/router';
import { BreadCrumb } from './shared/components/breadcrumb/bread-crumb';
import { jwtGuardCanActivateCpl } from '@app/core/guards';

export const routes: Routes = [
  { path: '', redirectTo: 'cpl', pathMatch: 'full' },
  {
    path: 'cpl',
    loadComponent: () => import('./views/cpl/cpl.component'),
    loadChildren: () => import('./views/cpl/cpl.routes'),
    data: {
      breadcrumb: {
        skip: true,
      } as BreadCrumb,
    },
    canActivate: [jwtGuardCanActivateCpl],
  },
  {
    path: 'auth',
    loadChildren: () => import('./views/auth/auth.routes'),
  },
  {
    path: 'forbidden',
    loadChildren: () => import('./views/auth/auth.routes'),
  },
];
